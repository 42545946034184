import React from 'react';
import Link from 'next/link';

import { Button, Heading, Text } from '@grupoboticario/flora-react';

import Styled from './AccessibilitySectionStyles';
import content from 'src/common/content';
import sendClickEvent from 'src/services/googleAnalytics/helpers/sendClickEvent';
import { ArrowForward } from '@material-ui/icons';

interface IAccessibleCatalogLink {
  href: string;
}

interface IAccessibilitySection {
  device: 'mobile' | 'desktop';
  href: string;
}

const AccessibilityText = () => (
  <Styled.TextWrapper>
    <Heading
      as="h3"
      level="6"
      color="$dark-dark"
      css={{ textAlign: 'start !important', paddingBottom: '$4' }}
    >
      Recursos de acessibilidade.
    </Heading>
    <Text as="p" size="bodyLargeShort">
      O catálogo de produtos oferece os seguintes recursos:
    </Text>
    <Text as="ul" size="bodyLargeShort">
      <li>Suporte para leitor de tela</li>
      <li>Aumento da fonte de leitura</li>
      <li>Alto contraste</li>
    </Text>
    <Text as="p" size="bodyLargeShort" css={{ paddingBottom: '$4' }}>
      Se você precisa utilizar algum desses recursos para navegar, clique no
      botão visitar catálogo acessível.
    </Text>
  </Styled.TextWrapper>
);

const AccessibleCatalogLink = ({ href }: IAccessibleCatalogLink) => (
  <Link href={href} passHref>
    <Button
      as="a"
      isFull
      variant="standard"
      rightIcon={<ArrowForward style={{ width: '1em', height: '1em' }} />}
      data-testid="visit-accessible-catalog"
      onClick={sendClickEvent.accessAccessibleCatalog}
    >
      {`Visitar ${content.platform.toLowerCase()} acessível`}
    </Button>
  </Link>
);

export const AccessibilitySection = ({
  device,
  href,
}: IAccessibilitySection) => {
  return (
    <Styled.Wrapper device={device}>
      {device === 'desktop' && <hr aria-hidden="true" />}
      <Styled.DesktopRow>
        <AccessibilityText />
        <AccessibleCatalogLink href={href} />
      </Styled.DesktopRow>
    </Styled.Wrapper>
  );
};
