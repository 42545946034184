import { styled, Text } from '@grupoboticario/flora-react';

export default {
  Wrapper: styled('div', {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'nowrap',
    padding: '$3 $4',
    width: '100%',
    cursor: 'pointer',
    backgroundColor: '$brand-1',

    button: {
      color: '$light-light',
      paddingRight: '$0',
    },

    '@tablet': {
      justifyContent: 'center',

      button: {
        marginLeft: '$1',
      },
    },
  }),

  CurrentStateInfo: styled(Text, {
    width: '100%',
    color: '$light-light',

    '@tablet': {
      width: 'auto',
    },
  }),
};
