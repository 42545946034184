import { styled, Text } from '@grupoboticario/flora-react';
import { Select } from 'src/styles';

export default {
  Form: styled('form', {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '$3 $4',
    width: '100%',

    button: {
      width: '100%',
    },

    '@tablet': {
      justifyContent: 'space-between',
      maxWidth: '500px',

      button: {
        width: '48%',
      },
    },
  }),

  LabelDiv: styled('label', {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'nowrap',
    marginBottom: '$4',
    width: '100%',

    svg: {
      width: '24px',
      marginRight: '$2',

      'path, circle': {
        fill: '$brand-1',
      },
    },

    '@tablet': {
      justifyContent: 'center',
      marginTop: '$1',
    },
  }),

  Label: styled(Text, {
    background: 'transparent',
    color: '$dark-dark',
    width: '100%',

    '@tablet': {
      width: 'auto',
    },
  }),

  Select: styled(Select, {
    height: '48px',
    marginBottom: '$4',
    width: '100%',

    '@tablet': {
      width: '48%',
      marginBottom: '$2',
    },
  }),
};
