import { styled, Text } from '@grupoboticario/flora-react';

export default {
  Wrapper: styled('div', {
    alignItems: 'center',
    background: 'transparent',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '$3 $4',
    width: '100%',

    '@tablet': {
      flexWrap: 'nowrap',
      justifyContent: 'center',
    },
  }),

  Label: styled('div', {
    alignItems: 'center',
    background: 'transparent',
    display: 'flex',
    flexWrap: 'nowrap',
    padding: '$0',
    width: '100%',

    svg: {
      width: '24px',
      marginRight: '$2',
      'path, circle': {
        fill: '$brand-1',
      },
    },

    '@tablet': {
      width: 'auto',
    },
  }),

  CurrentStateInfo: styled(Text, {
    color: '$dark-dark',
    width: '100%',
  }),

  ButtonsContainer: styled('div', {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    padding: '$2 $0 $0',
    width: '100%',

    button: {
      marginLeft: '$4',
    },

    '@tablet': {
      padding: '$0',
      width: 'auto',
    },
  }),
};
