import { styled, Heading } from '@grupoboticario/flora-react';

export default {
  Wrapper: styled('div', {
    display: 'block',
    margin: '$0 auto',
    maxWidth: '360px',
    padding: '$6 $0',
    width: '100%',
  }),

  InstructionsSubtitle: styled(Heading, {
    color: '$dark-light',
    margin: '$0 $0 $5',
  }),
};
