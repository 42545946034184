import { styled } from '@grupoboticario/flora-react';

export default {
  Wrapper: styled('div', {
    alignItems: 'center',
    background: '$light-light',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '-1px',

    '@tablet': {
      flexWrap: 'nowrap',
    },
  }),
};
