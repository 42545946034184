// Modules
import React from 'react';

// Styles
import Styled from './SelectStateStyled';

// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Components
import { Button } from '@grupoboticario/flora-react';

interface SelectedStateInterface {
  state: string;
  onChangeStateClick: () => void;
}

const SelectedState = (props: SelectedStateInterface): JSX.Element => {
  return (
    <Styled.Wrapper onClick={props.onChangeStateClick}>
      <Styled.CurrentStateInfo size="auxiliarSmall">
        {`Preços válidos para o Estado de ${props.state}`}
      </Styled.CurrentStateInfo>
      <Button
        css={{
          boxShadow: 'none',
          height: 'auto',
          padding: '$0',
        }}
        variant="ghost"
        data-testid="SelectedState-changeState-button"
        onClick={props.onChangeStateClick}
        aria-label="Alterar estado"
      >
        <ExpandMoreIcon />
      </Button>
    </Styled.Wrapper>
  );
};

export default SelectedState;
