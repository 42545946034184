import { styled } from '@grupoboticario/flora-react';

export default {
  Wrapper: styled('div', {
    padding: '$6 0',

    '@tablet': {
      flexDirection: 'column',
      width: '100%',
      maxWidth: '760px',
      margin: 'auto',

      hr: {
        margin: '$2 -$12 $8',
      },

      a: {
        maxWidth: '320px',
        margin: 'auto 0',
      },
    },

    variants: {
      device: {
        mobile: {
          '@tablet': {
            display: 'none',
          },
        },
        desktop: {
          display: 'none',

          '@tablet': {
            display: 'flex',
          },
        },
      },
    },
  }),

  TextWrapper: styled('div', {
    ul: {
      padding: '$4 0',
      li: {
        paddingLeft: '$2',
        listStyle: 'disc inside none',
      },
    },

    '@tablet': {
      maxWidth: '320px',
    },
  }),

  DesktopRow: styled('div', {
    '@tablet': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  }),
};
