import { styled } from '@grupoboticario/flora-react';

export default {
  Wrapper: styled('div', {
    position: 'relative',
    padding: '$0',
  }),

  CycleInfo: styled('div', {
    alignItems: 'center',
    background: '$brand-1',
    bottom: '-24px',
    display: 'flex',
    height: '24px',
    left: 0,
    padding: '$0 $2',
    position: 'absolute',
    zIndex: 9,
    borderRadius: '$small',
  }),
};
