import React from 'react';
import { format, parseISO } from 'date-fns';

import { Text } from '@grupoboticario/flora-react';

import { ICycle } from 'src/common/types/CatalogType';

import Styled from './CatalogCycleStyled';

interface CatalogCycleInterface {
  cycle: ICycle;
}

const CatalogCycle = (props: CatalogCycleInterface): JSX.Element => {
  return (
    <Styled.Wrapper id="catalog-info">
      <Styled.CycleInfo
        aria-label={`Ciclo ${props.cycle.number}, de ${format(
          parseISO(props.cycle.start),
          'dd/MM',
        )} a ${format(parseISO(props.cycle.end), 'dd/MM')}`}
        data-testid="catalog-info-cycle"
      >
        <Text
          as="span"
          color="$actionNavigationAltStatic"
          size="overline"
        >{`Ciclo ${props.cycle.number}`}</Text>
      </Styled.CycleInfo>
    </Styled.Wrapper>
  );
};

export default CatalogCycle;
