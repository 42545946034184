// Modules
import React from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import slugify from 'react-slugify';
import { useHotkeys } from 'react-hotkeys-hook';

// Types
import { ICycle, IPage } from 'src/common/types/CatalogType';

//BrandContent
import content from 'src/common/content';

// Styles
import Styled from './LandingPageStyle';

//Components
import Header from 'src/components/organisms/Header';
import Footer from 'src/components/organisms/Footer';
import Localization from 'src/components/organisms/Localization';
import ShareOptions from 'src/components/organisms/ShareOptions';
import CatalogCycle from 'src/components/organisms/CatalogCycle';
import { Button, Heading } from '@grupoboticario/flora-react';
import { ArrowForward } from '@material-ui/icons';
import Link from 'next/link';
import { handleHotKeysEvent } from 'src/common/helpers/analyticsEvents';
import sendClickEvent from 'src/services/googleAnalytics/helpers/sendClickEvent';
import { useStyle } from 'src/contexts/StyleContext';
import { AccessibilitySection } from 'src/components/organisms/AccessibilitySection';
import { getCatalogUrl } from 'src/common/helpers/getCatalogUrl';

interface ILandingPage {
  uf?: string;
  catalogCycle: ICycle;
  catalogCover: IPage;
  pdfSrc: string;
}
interface IImageContainer {
  device: 'mobile' | 'desktop';
  href: string;
  catalogCycle: ICycle;
  imageSrc: string;
}

interface IButtonsContainer {
  href: string;
}

const ImageContainer = ({
  device,
  href,
  catalogCycle,
  imageSrc,
}: IImageContainer) => {
  const { style } = useStyle();

  return (
    <Styled.ImageContainer device={device} contrast={style.contrast}>
      <CatalogCycle cycle={catalogCycle} />
      <Link href={href} passHref>
        <a aria-label={`Acessar ${content.platform}`}>
          <img src={imageSrc} />
        </a>
      </Link>
    </Styled.ImageContainer>
  );
};

const ButtonsContainer = ({ href }: IButtonsContainer) => {
  return (
    <Styled.ButtonsContainer>
      <Link href={href} passHref>
        <Button
          as="a"
          isFull
          variant="conversion"
          rightIcon={<ArrowForward style={{ width: '1em', height: '1em' }} />}
          onClick={sendClickEvent.accessCatalog}
        >
          {`Acessar ${content.platform}`}
        </Button>
      </Link>
    </Styled.ButtonsContainer>
  );
};

const LandingPage = ({
  uf = '',
  catalogCycle,
  catalogCover,
  pdfSrc,
}: ILandingPage): JSX.Element => {
  const router = useRouter();

  const catalogUrl = getCatalogUrl({ uf });

  useHotkeys('Alt+3', () => {
    handleHotKeysEvent('3');
    // @ts-ignore
    startDownloadFile(pdfSrc);
  });

  const onUfSelection = (uf: string): void => {
    router.push(`/${slugify(uf)}`);
  };

  const VIEW_CATALOG_URL = `/${(
    uf || content.defaultState
  ).toLowerCase()}/visualizar`;

  return (
    <>
      <Head>
        <title>{`${content.platform} Digital - ${content.name}`}</title>
        <meta
          name="description"
          content={`Confira os principais destaques, lançamentos e promoções de ${content.name} no ciclo ${catalogCycle.number} com o nosso ${content.platform} Digital!`}
        />
        <link rel="preload" as="image" href={catalogCover.image} />
      </Head>
      <Header />
      <Styled.Main>
        <Localization uf={uf} onUfSelection={onUfSelection} />
        <Styled.Wrapper>
          <AccessibilitySection device="desktop" href={VIEW_CATALOG_URL} />
          <Styled.DesktopFirstRow>
            <Styled.ContentWrapper>
              <AccessibilitySection device="mobile" href={VIEW_CATALOG_URL} />
              <div>
                <Heading as="h3" level="6" color="$dark-dark">
                  Confira as novidades do Ciclo {catalogCycle.number}.
                </Heading>
                <ImageContainer
                  device={'mobile'}
                  catalogCycle={catalogCycle}
                  href={catalogUrl}
                  imageSrc={catalogCover.image}
                />
                <ButtonsContainer href={catalogUrl} />
                <Styled.ShareSectionWrapper>
                  <Styled.ShareWrapper>
                    <ShareOptions state={uf.toLowerCase()} />
                  </Styled.ShareWrapper>
                </Styled.ShareSectionWrapper>
              </div>
            </Styled.ContentWrapper>
            <ImageContainer
              device={'desktop'}
              catalogCycle={catalogCycle}
              href={catalogUrl}
              imageSrc={catalogCover.image}
            />
          </Styled.DesktopFirstRow>
        </Styled.Wrapper>
      </Styled.Main>
      <Footer />
    </>
  );
};

export default LandingPage;
