// Modules
import React from 'react';

// Styles
import Styled from './DefaultSelectedStateStyled';

// Icons
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';

// Components
import { Button } from '@grupoboticario/flora-react';

// Helpers
import { stateName } from 'src/common/helpers';

interface DefaultSelectedStateInterface {
  defaultState: string;
  onChangeUfClick: () => void;
  onConfirmUfClick: (state: string) => void;
}

const DefaultSelectedState = (
  props: DefaultSelectedStateInterface,
): JSX.Element => {
  const handleConfirmState = (): void => {
    props.onConfirmUfClick(props.defaultState);
  };

  return (
    <Styled.Wrapper>
      <Styled.Label>
        <RoomOutlinedIcon aria-hidden="true" />
        <Styled.CurrentStateInfo size="auxiliarSmall">
          Você está vendo os preços e promoções para o Estado de{' '}
          {stateName[props.defaultState]}
        </Styled.CurrentStateInfo>
      </Styled.Label>
      <Styled.ButtonsContainer>
        <Button
          size="small"
          variant="ghost"
          data-testid="DefaultSelectedState-changeState-button"
          onClick={props.onChangeUfClick}
          aria-label="Trocar estado"
        >
          Trocar
        </Button>
        <Button
          size="small"
          data-testid="DefaultSelectedState-confirm-button"
          onClick={handleConfirmState}
          aria-label="Confirmar estado"
        >
          Confirmar
        </Button>
      </Styled.ButtonsContainer>
    </Styled.Wrapper>
  );
};

export default DefaultSelectedState;
