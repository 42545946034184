import React from 'react';

import content from 'src/common/content';

import Styled from './ShareOptionsStyled';

import SharedButtons from 'src/components/molecules/SharedButtons';

const ShareOptions = ({ state = '' }: { state?: string }): JSX.Element => {
  return (
    <Styled.Wrapper>
      <Styled.InstructionsSubtitle level="6">
        Compartilhar {content.platform.toLowerCase()}.
      </Styled.InstructionsSubtitle>

      <SharedButtons url={state} testId="ShareOptions" />
    </Styled.Wrapper>
  );
};

export default ShareOptions;
