import { styled } from '@grupoboticario/flora-react';

export default {
  Main: styled('main', {
    backgroundColor: '$brand-1',
  }),

  Wrapper: styled('div', {
    borderTopLeftRadius: '$large',
    borderTopRightRadius: '$large',
    backgroundColor: '$light-dark-2',

    '@tablet': {
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      padding: '$7 $0',
    },
  }),

  ContentWrapper: styled('div', {
    padding: '$6 $5 $0',
    display: 'flex',
    flexDirection: 'column-reverse',

    h3: {
      width: '100%',
      textAlign: 'center',
      paddingBottom: '$4',
      color: '$dark-light',
    },

    '@tablet': {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto 0',
      padding: '$6 $0 $0',

      h3: {
        maxWidth: '320px',
        margin: 'auto',
        textAlign: 'start',
        paddingBottom: '$0',
      },
    },
  }),

  ImageContainer: styled('div', {
    boxShadow: '-4px 0px 24px -8px rgba(0, 0, 0, 0.4)',
    borderRadius: '$small',
    overflow: 'hidden',

    img: {
      display: 'block',
    },

    variants: {
      device: {
        mobile: {
          width: '75%',
          margin: '0 auto',

          img: {
            width: '100%',
          },

          '@tablet': {
            display: 'none',
          },
        },
        desktop: {
          display: 'none',

          '@tablet': {
            display: 'block',
            margin: 'auto 0',

            img: {
              width: 'auto',
              height: 'calc(100vh - 364px)',
              minHeight: '440px',
              cursor: 'pointer',
            },
          },
        },
      },
      contrast: {
        true: {
          filter: 'grayscale(100%)',
        },
      },
    },
  }),

  ButtonsContainer: styled('div', {
    padding: '$4 $0 $6',

    a: {
      marginTop: '$4',
    },

    '@tablet': {
      maxWidth: '320px',
      margin: 'auto',
    },
  }),

  ShareSectionWrapper: styled('section', {
    width: '100vw',
    padding: '$2 $0',
    backgroundColor: '$light-dark-1',
    margin: '$0 -$5',

    '@tablet': {
      width: '100%',
      maxWidth: '320px',
      margin: 'auto',
      backgroundColor: 'transparent',
    },
  }),

  ShareWrapper: styled('div', {
    width: '100%',
    padding: '$0 $5',
    backgroundColor: '$light-dark-2',

    '@tablet': {
      padding: '$0',
    },
  }),

  DesktopFirstRow: styled('div', {
    '@tablet': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      maxWidth: '840px',
      margin: 'auto',
    },
  }),
};
