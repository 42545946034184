// Modules
import React, { useEffect, useState } from 'react';
import slugify from 'react-slugify';

// Styles
import Styled from './LocalizationStyled';

// Components
import DefaultSelectedState from 'src/components/organisms/Localization/DefaultSelectedState';
import SelectedState from 'src/components/organisms/Localization/SelectedState';
import StateSelector from 'src/components/organisms/Localization/StateSelector';

// Helpers
import { stateName } from 'src/common/helpers';

// Services
import sendGoogleAnalytics from 'src/services/googleAnalytics/sendGoogleAnalytics';

interface LocalizationInterface {
  uf?: string;
  onUfSelection: (uf: string) => void;
}

const defaultSelectedState = process.env.DEFAULT_STATE;

const Localization = (props: LocalizationInterface): JSX.Element => {
  const [showSelector, setShowSelector] = useState(false);

  useEffect(() => {
    setShowSelector(false);
  }, [props.uf]);

  const handleUfSelection = (uf: string): void => {
    if (uf === props.uf) {
      setShowSelector(false);
    } else {
      props.onUfSelection(uf);
    }

    sendGoogleAnalytics.event({
      eventCategory: `${process.env.BRAND_IDENTIFIER}:catalogo-virtual:geral`,
      eventAction: 'clique:botao',
      eventLabel: 'localizacao:confirmar',
      dimension4: slugify(stateName[uf]),
    });
  };

  const handleChangeUfClick = (): void => {
    setShowSelector(true);

    sendGoogleAnalytics.event({
      eventCategory: `${process.env.BRAND_IDENTIFIER}:catalogo-virtual:geral`,
      eventAction: 'clique:botao',
      eventLabel: 'localizacao:trocar',
    });
  };

  return (
    <Styled.Wrapper>
      {showSelector ? (
        <StateSelector
          state={props.uf || defaultSelectedState}
          onFormSubmit={handleUfSelection}
        />
      ) : props.uf ? (
        <SelectedState
          state={stateName[props.uf]}
          onChangeStateClick={handleChangeUfClick}
        />
      ) : (
        <DefaultSelectedState
          defaultState={defaultSelectedState}
          onChangeUfClick={handleChangeUfClick}
          onConfirmUfClick={handleUfSelection}
        />
      )}
    </Styled.Wrapper>
  );
};

export default Localization;
