// Modules
import React, { useState } from 'react';

// Styles
import Styled from './StateSelectorStyled';

// Icons
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';

// Components
import { Button } from '@grupoboticario/flora-react';

// Brand Content
import content from 'src/common/content';

// Helpers
import { stateName } from 'src/common/helpers';

interface StateSelectorInterface {
  state: string;
  onFormSubmit: (state: string) => void;
}

const StateSelector = (props: StateSelectorInterface): JSX.Element => {
  const [selectedState, setSelectedState] = useState(props.state);

  const handleSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    setSelectedState(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    props.onFormSubmit(selectedState);
  };

  const orderStatesAlphabetically = (statesList: string[]): string[] => {
    return statesList.sort((stateA, stateB) => {
      if (stateName[stateA] > stateName[stateB]) {
        return 1;
      } else {
        return -1;
      }
    });
  };

  return (
    <Styled.Form onSubmit={handleSubmit}>
      <Styled.LabelDiv>
        <RoomOutlinedIcon aria-hidden="true" />
        <Styled.Label size="auxiliarSmall">
          Escolha seu estado para ver o {content.platform.toLowerCase()} com
          preços e promoções para a sua região.
        </Styled.Label>
      </Styled.LabelDiv>
      <Styled.Select
        data-testid="StateSelector-select"
        value={selectedState}
        onChange={handleSelectChange}
      >
        {orderStatesAlphabetically(content.states).map((state) => (
          <option key={state} value={state}>
            {stateName[state]}
          </option>
        ))}
      </Styled.Select>
      <Button
        data-testid="StateSelector-submit-button"
        type="submit"
        variant="conversion"
        aria-label="Confirmar estado"
      >
        Confirmar
      </Button>
    </Styled.Form>
  );
};

export default StateSelector;
